<template>
  <div class="ideal-selector">
    <h3 class="ideal-selector__title">
      {{ "Pay with iDeal" | t }} <i class="icon--ideal"></i>
    </h3>

    <div v-if="isIssuerSelected" class="ideal-selector__selected-issuer">
      <div>
        {{ "Bank selection" | t }}:
        <i
          class="icon--ideal-bank"
          :style="{ 'background-image': `url('${selectedIssuer.image}')` }"
        ></i>
        {{ selectedIssuer.name }}
      </div>

      <div>
        <button class="link" @click.prevent="showBankSelector = true">
          {{ "Change" | t }}
        </button>
      </div>
    </div>

    <button
      v-if="!issuer"
      class="button--primary is-full-width"
      @click.prevent="showBankSelector = true"
    >
      {{ "Select your bank" | t }}
    </button>
  </div>
</template>

<script>
export default {
  name: "CheckoutPaymentIdealSelector",
  props: {
    issuer: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showBankSelector: false,
      selectedIssuer: {},
    };
  },
  computed: {
    isIssuerSelected() {
      return !!this.selectedIssuer.code;
    },
  },
  methods: {
    handleIssuerSelected($event) {
      this.selectedIssuer = $event;
      this.showBankSelector = false;
      this.$emit("ideal-issuer-selected", this.selectedIssuer.code);
    },
  },
};
</script>
